/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "I’ve said before that symptoms of mental health are a bit like a pick and mix, you don’t get to choose which symptoms you get and everyone’s can be different. I remember scrolling through the ", React.createElement(_components.a, {
    href: "https://www.nhs.uk/mental-health/conditions/social-anxiety/"
  }, "NHS website"), " and resonating with all of the symptoms listed under social anxiety disorder, so I thought I’d list some of my struggles/symptoms in a bit more detail. "), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Constant feeling of negative judgement - I think this is the overarching symptom of social anxiety. Fearing that people are going to think negative things about you underpins a lot of the symptoms. I’d assume people didn’t like me before they’d even met me."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Inability to go places alone - I would DREAD having to go somewhere by myself and developed a safety behaviour of calling someone every time I was walking somewhere alone. This also affected my attendance at college/uni."), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "New places/experiences - I’m talking about going to a new place, a new building etc. and starting new hobbies, classes etc. The fear of the unknown. I used to worry about how to get into buildings, whether doors were push/pull/automatic, would there be an intercom system, what the temperature was like, what the people would be like and would they be friendly to a new person or were they an established clique uninterested in making new friends…? I would actually check out the location on google maps to familiarise myself with the area/ door situation before going in person. "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Eating - I would hate eating in front of people. I had a constant fear that I was a really ugly, messy eater and everyone would judge me for it. Or that something would get stuck in my teeth or around my mouth and everyone would be laughing at me behind my back. This led to me not eating in public or being really picky about where I did eat and who I would eat around (it wasn’t an issue with my family/friends). "), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Existing hobbies - Social anxiety stopped me enjoying hobbies I loved. I started dancing at an older age than most people (I was about 11/12 when I started attending classes regularly, before this I would attend a taster session and never go back). In my later teens I started to get headaches every day before my class and I didn’t understand why. I started to dread going dancing and it didn’t make sense to me because it was something I enjoyed doing whilst I was there (& was good for my mental health). My parents ended up seeing this behaviour as me being uninterested and I stopped going when I was about 16/17. At uni I was unable to attend dance society classes regularly until my 3rd year."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Phone calls - I HATED phone calls. Not just a symptom of being a millennial.. I found it difficult to make or take calls. This meant I’d struggle ordering takeaways, making appointments, making enquiries. I’d rather let my phone ring then google the phone number afterwards instead of just answering it. I’d also have to write down or practice what I was going to say before calling someone. "), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Work/ interviews - The whole process of completing an application, having to attend an interview and then actually attending a place of work to interact with colleagues and the general public brought a lot of anxiety and panic to me. As a result I either chose jobs with limited social interaction (eg newspaper deliverer) or didn’t work at all."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Exams/presentations - My main battle with social anxiety was whilst I was at uni, where my knowledge and capabilities were assessed via coursework, exams and presentations. I’ve got a separate blog post on exams if you want to ", React.createElement(_components.a, {
    href: "https://www.anxiousextrovert.co.uk/blog/exams-and-social-anxiety/"
  }, "read an example"), " of how my anxiety has affected my ability to perform well in an exam. As coursework is completed in your own time and pace, this didn’t really trigger my anxiety. Presentations on the other hand seem like an obvious struggle for people with social anxiety and this was certainly the case for me. I’d forget to breathe whilst presenting to the point my chest would hurt and my words would just come out as word vomit.\\"), "\n", React.createElement("ins", {
    class: "adsbygoogle",
    "data-ad-layout": "in-article",
    "data-ad-format": "fluid",
    "data-ad-client": "ca-pub-1001229702754753",
    "data-ad-slot": "8446829758"
  }), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "Physical symptoms - heart racing, heart palpitations, sweating, feeling faint, feeling sick, constant worrying, hyperventilating, staying quiet, overthinking & issues sleeping when worrying. "), "\n"), "\n"), "\n", React.createElement(_components.p, null, "“Anxiety is a thin stream of fear trickling through the mind. If encouraged, it cuts a channel into which all other thoughts are drained.” - Arthur Somers Roche"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
